import create from "zustand";
import { DateTimeRange } from "@ingka-group-digital/lib-date-time";

type Filters = {
    unit: string[];
    division: string[];
    activity: number[];
    costCenter: string[];
    department: string[];
};

export interface CommonStore {
    openSurveyPopup: boolean;
    setOpenSurveyPopup: (isOpen: boolean) => void;

    openMaintenancePopup: boolean;
    setOpenMaintenancePopup: (isOpen: boolean) => void;

    isDevToolsVisible: boolean;
    setDevToolsVisible: (visible: boolean) => void;

    lastAvailLockingDate: DateTimeRange | null;
    setLastAvailLockingDate: (date: DateTimeRange | null) => void;

    filters: Filters;
    setFilters: (filters: Filters) => void;
}

const commonStore = create<CommonStore>((set, get) => ({
    openSurveyPopup: false,
    setOpenSurveyPopup: (isOpen: boolean) => set({ openSurveyPopup: isOpen }),
    openMaintenancePopup: false,
    setOpenMaintenancePopup: (isOpen: boolean) => {
        set({ openMaintenancePopup: isOpen });
    },
    lastAvailLockingDate: null,
    setLastAvailLockingDate: (date: DateTimeRange | null) => {
        set({ lastAvailLockingDate: date });
    },

    isDevToolsVisible: true,
    setDevToolsVisible: (visible: boolean) => {
        set({ isDevToolsVisible: visible });
    },

    filters: { unit: [], division: [], department: [], activity: [], costCenter: [] },
    setFilters: (filters: Filters) => {
        set({ filters });
    },
}));

export default commonStore;
