type ErrorMetadata = {
    code: number;
    param?: string;
};
export class ErrorMessage extends Error {
    public metadata: ErrorMetadata[] = [];

    getFirstCode() {
        return this.metadata.length ? this.metadata[0].code : ErrorCode.UNKNOWN_ERROR;
    }
}

export class AuthError extends Error {}
export class NotAuthorizedError extends Error {}

export class ApiResponseError extends ErrorMessage {
    constructor(public responseCode: number, public responseBody: any) {
        // compat with previous server validation api (needs to replaced)
        if (typeof responseBody === "string") {
            super(responseBody);
            this.metadata.push({ code: ErrorCode.LEGACY_ERROR });
        } else {
            // these error messages are internal;
            // they should not be displayed anywhere except the console when in DEV env
            super("Server returned error(s)");

            // error codes should be used to determine what error message
            // will be displayed in the error-handling component
            for (const e of responseBody.errors) {
                this.metadata.push(e);
            }
        }

        Object.setPrototypeOf(this, ApiResponseError.prototype);
    }
}

export class ClientError extends ErrorMessage {
    constructor(public errorCode: number = ErrorCode.UNKNOWN_ERROR) {
        super(`Application error, code ${errorCode}`);
        this.metadata.push({ code: errorCode });

        Object.setPrototypeOf(this, ClientError.prototype);
    }
}

// internal error codes for client validation
export enum ErrorCode {
    LEGACY_ERROR = 2002,
    NO_WEEKDAYS_MATCHED = 2004,
    UNEXPECTED_RESPONSE = 2500,
    UNKNOWN_ERROR = 3000,
    ACTIVITY_DATE_MISMATCH = 4200,
}
